import styles from "./styles.module.scss"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"

export const MessageModal: React.FC<any> = ({ account }) => 
{
    const { t }                                                                = useTranslation()
    const { openMessageModal, setOpenMessageModal, message, messageModalIcon } =
    useSubCore()

    return (
        openMessageModal && (
            <div className={styles.messageModal}>
                <div className={styles.container}>
                    <div className={styles.content}>
                        {messageModalIcon === "success" ? (
                            <div className={styles.image}>
                                <img src={account?.logo?._url} alt='teste' />
                            </div>
                        ) : (
                            <div className={styles.image}>
                                <img src='/assets/img/warning.png' alt='teste' />
                            </div>
                        )}
                        <div
                            className={styles.message}
                            dangerouslySetInnerHTML={{ __html : message }}
                        />
                        <div className={styles.control}>
                            <button onClick={() => setOpenMessageModal(false)}>
                                {t("Fechar")}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}
