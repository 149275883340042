import { CountdownCircleTimer } from "react-countdown-circle-timer"
import styles from "./styles.module.scss"
import { useCountdown } from "react-countdown-circle-timer"
import { useEffect } from "react"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"

const renderTime: React.FC<any> = ({ remainingTime }) => 
{
    return (
        <div className={styles.timer}>
            <div className={styles.value}>
                <span style={{ color : "white", fontWeight : "600", fontSize : "40px" }}>
                    {remainingTime}
                </span>
            </div>
        </div>
    )
}

export const TimeWrapper: React.FC<any> = ({
    timer,
    placeBet,
    checkCartChange,
}) => 
{
    const {
        path,
        pathLength,
        stroke,
        strokeDashoffset,
        remainingTime,
        elapsedTime,
        size,
        strokeWidth,
    } = useCountdown({
        isPlaying            : true,
        duration             : timer,
        colors               : "#abc",
        initialRemainingTime : timer,
    })

    const {
        setOpenMessageModal,
        setMessage,
        setMessageModalIcon,
        setOpenModalTimeLive,
        cartChanges,
        setCartChanges,
    } = useSubCore()

    const { t } = useTranslation()

    const checkout = async () => 
    {
        const result = await checkCartChange()

        if (result?.data?.changed) 
        {
            setMessageModalIcon("warning")
            const message = t("Dividendo Alterado")
            setMessage(message)
            setOpenMessageModal(true)
        }
        else 
        {
            setOpenModalTimeLive(false)
            setCartChanges(false)
            await placeBet()
        }
    }

    useEffect(() => 
    {
        setCartChanges(false)
    }, []);

    useEffect(() => 
    {

        if (remainingTime === 0 && elapsedTime === timer && !(remainingTime === 0 && elapsedTime === 0) && !cartChanges) 
        {
            setOpenModalTimeLive(false)
            checkout()
        }
        else if (cartChanges)
        {
            setOpenModalTimeLive(false)
            setCartChanges(false)
        }
    }, [ remainingTime, cartChanges ])

    return (
        <div className={styles.timerWrapper}>
            <CountdownCircleTimer
                isPlaying
                duration={timer}
                colors={[ "#FDB82C", "#FDB82C" ]}
                colorsTime={[ timer, 0 ]}
                onComplete={() => ({ shouldRepeat : false, delay : 1 })}
            >
                {renderTime}
            </CountdownCircleTimer>
        </div>
    )
}
