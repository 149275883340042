import { THEME_SETTING, SUBTRANSLANTE_MAP } from "@/setting/setting"
import { useTranslation } from "next-i18next"

export const subTranslate = (stringToTranslate, t) => 
{
  
    if (SUBTRANSLANTE_MAP && SUBTRANSLANTE_MAP[stringToTranslate as keyof typeof SUBTRANSLANTE_MAP])
    {
        return SUBTRANSLANTE_MAP[stringToTranslate as keyof typeof SUBTRANSLANTE_MAP];
    }

    if (stringToTranslate.includes("Cassino Primeiro deposito"))
    {

        return stringToTranslate.replace("Cassino Primeiro deposito", "카지노 첫충")
    }

    if (stringToTranslate.includes("Cassino Maechung"))
    {

        return stringToTranslate.replace("Cassino Maechung", "	카지노 매충")
    }

    return t(stringToTranslate)

    
}