import { useCore } from '@/core-nextv3/core/core'
import styles from './styles.module.scss'
import { useTranslation } from 'next-i18next'

export function AnimatedLoading() {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      {/* <span>C</span>
      <span>a</span>
      <span>r</span>
      <span>r</span>
      <span>e</span>
      <span>g</span>
      <span>a</span>
      <span>n</span>
      <span>d</span>
      <span>o</span>
      <span>.</span>
      <span>.</span>
      <span>.</span> */}
      <span>{t('Aguarde')}...</span>
    </div>
  )
}
