import styles from "./styles.module.scss"
import { Dialog } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import WarningIcon from "@mui/icons-material/Warning"
import { useTranslation } from "next-i18next"

export const AlertModal: React.FC<any> = ({
    open,
    onClose,
    handler,
    message,
}: any) => 
{
    const { t } = useTranslation()

    const handleCloseDialog = (event: any, reason: any) => 
    {
        if (reason !== "backdropClick") 
        {
            onClose(false)
        }
    }

    return (
        <Dialog
            className={styles.alertModal}
            open={open}
            onClose={handleCloseDialog}
        >
            <div className={styles.content}>
                <div className={styles.header}>
                    <h1>{t("Atenção")}</h1>
                    <CloseIcon
                        className={styles.closeButton}
                        onClick={() => onClose(false)}
                    />
                </div>
                <div className={styles.message}>
                    <WarningIcon />
                    <h1>{t(message)}</h1>
                </div>
                <div className={styles.controls}>
                    <button onClick={() => onClose(false)}>{t("Não")}</button>
                    <button onClick={() => handler()}>{t("Sim")}</button>
                </div>
            </div>
        </Dialog>
    )
}
