import { useResponsive } from "@/core-nextv3/util/useResponsive";
import styles from "./styles.module.scss"


export const BannersLinks: React.FC<any> = ({ sizeBanners }:any) => 
{           
    const { isDesktop } = useResponsive()

    const handleBannerClick = (link: any) => 
    {
        if (link) 
        {
            window.open(link);
        }
    };

    
    return <div className={styles.imagesLink}>
        {sizeBanners?.bannersLinks && sizeBanners?.bannersLinks?.map((banner: any) => (
            (isDesktop || banner?.mobile) && <div key={banner?.id}>
                <img
                    alt=""
                    className={styles.imageLink}
                    src={isDesktop ? banner?.imageBanner?._url : banner?.mobile?._url}
                    onClick={() => handleBannerClick(banner?.url)}
                />
            </div>
        ))}
    </div>
}
