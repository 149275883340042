import { AiFillStar } from "react-icons/ai"
import { HiEnvelope } from "react-icons/hi2"
import styles from "../styles.module.scss"
import { useTranslation } from "next-i18next"

export const AlertPopup:React.FC<any> = ({ counter, handler }:any) => 
{
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            <div className={styles.content} onClick={(e) => handler(e)}>
                <div className={styles.popupHeader}>
                    <div className={styles.titleArea}>
                        <AiFillStar />
                        <span className={styles.title}>
                            {t("Verifique as notificações")}
                        </span>
                        <AiFillStar />
                    </div>
                </div>
                <div className={styles.popupBody}>
                    <div className={styles.bodyContent}>
                        <HiEnvelope />
                        <span>
                            {t("Chegaram")} {counter} {t("Mensagem(s)")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}