import styles from "./styles.module.scss";
import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { FaUser } from "react-icons/fa";
import { CiLogin } from "react-icons/ci";
import { useTranslation } from "next-i18next";
import { AiOutlineClose } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../../../../public/assets/img/logo.png"
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { LuUser, LuUserCog, LuUserPlus } from "react-icons/lu";
import React from "react";
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { CiViewList } from "react-icons/ci";
import { DrawerLeft } from "./DrawerLeft";
import { DrawerRight } from "./DrawerRight";
import { background } from "@chakra-ui/react";
import { get } from "lodash";
import { getDocument } from "@/core-nextv3/document/document.api";
import { setting } from "@/core-nextv3/util/setting";
import { HOME_PAGE_SETTING } from "@/setting/setting";

export const HeaderMobile = ({
    account,
    setOpenLoginModal,
    setTypeLogin,
    setDisableClose,
    handleLogoutClick,
    openSubMenu,
    setOpneSubMenu,
    setReloadGames,
    clickRef,
    setSelectedSport,
    homePage
}: any) =>
{
    const { t }                             = useTranslation();
    const { user }                          = useContext(AuthContext);
    const pagePath                          = useRouter().asPath
    const [ open, setOpen ]                 = useState(false);
    const [ openRight, setOpenRight ]       = useState(false);
    const router                            = useRouter();
    const [ bannersGames, setBannersGames ] = useState<any>([]);

    const toggleDrawer = (newOpen: boolean) => 
    {
        setOpen(newOpen);
    }

    const toggleDrawerRight = (newOpen: boolean) => 
    {
        setOpenRight(newOpen);
    }

    const handleLogoLink = async (path) => 
    {

        if ( router.asPath === "/") 
        {
            router.reload();
        }
        else 
        {
            await router.push(path);
        }
    }
    

    const getHome = async () => 
    {
        const res = await getDocument(HOME_PAGE_SETTING)

        setBannersGames(res?.data?.bannersGames)
    }

    useEffect(() => 
    {
        getHome()
    }, [])

    
    return (
        <>
            <div className={styles.headerMobile}>
                <div className={styles.contentMobile}>
                    <Drawer sx={{ ".MuiDrawer-paperAnchorLeft" : { height : "100%" } }} open={open} onClose={() => toggleDrawer(false)}>
                        <DrawerLeft toggleDrawer={toggleDrawer} handleLogoutClick={handleLogoutClick}/>
                    </Drawer>

                    <Drawer open={openRight} anchor={"right"} onClose={ () => toggleDrawerRight(false)}>
                        <DrawerRight toggleDrawer={toggleDrawerRight} bannersGames={bannersGames}/>
                    </Drawer>

                    <div className={styles.menuBtnMobile}>
                        <div className={styles.menuBtnGrp}>
                            <div className={styles.languageWrap}>
                                <LuUser
                                    onClick={() => toggleDrawer(true)}
                                />
                            </div>
                        
                        </div>
                    </div>
                
                    <div className={styles.logoMenu}>
                        <a onClick={() => handleLogoLink("/") } className={styles.logo}>
                            <img
                                src={account?.logo?._url || logo}
                                alt='logo'

                            />
                        </a>
                    </div>

                    <div className={styles.menuBtnMobile}>
                        <div className={styles.menuBtnGrp}>
                            <div className={styles.languageWrap}>
                                <RxHamburgerMenu
                                    onClick={() => toggleDrawerRight(true)}
                                />
                            </div>
                            
                       
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}