import styles from "./styles.module.scss"
import Link from "next/link"
import { useRouter } from "next/router"
import { ModalLogin } from "../ModalLogin"

import { useSubCore } from "@/hooks/useSubCore"
import { RxHamburgerMenu } from "react-icons/rx"
import { useCore } from "@/core-nextv3/core/core"
import { AuthContext } from "../context/AuthContext"
import logo from "../../../public/assets/img/logo.png"
import { useContext, useEffect, useRef, useState } from "react"
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai"
import { PopupsModal } from "../PopupsModal"
import { NotificationPopup } from "../NotificationPopup"
import { useTranslation } from "next-i18next"
import { CiClock1, CiLogin } from "react-icons/ci"
import { FaUser } from "react-icons/fa"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { FirebaseContext } from "../context/FirebaseContext"
import dynamic from "next/dynamic"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import { getAccid } from "@/core-nextv3/util/util"


export const HeaderTwo: React.FC<any> = ({
    fixtures,
    sports,
    locations,
    banks,
    dividend,
    account
}:any) => 
{

    const router                            = useRouter()
    const pagePath                          = useRouter().asPath
    const [ openSubMenu, setOpneSubMenu ]   = useState(false)
    const [ disableClose, setDisableClose ] = useState(false)
    const [ popupsToShow, setPopupsToShow ] = useState<any>([])
    const [ openPopups, setOpenPopups ]     = useState(false)

    const [ messageCounterAuto, setMessageCounterAuto ]         = useState<any>(0)
    const [ messageCounterAnswered, setMessageCounterAnswered ] = useState<any>(0)

    const firebaseApp = useContext(FirebaseContext)

    const clickRef: any = useRef(null)

    const { awaitLoadingHTTP } = useCore()
    const { setSelectedSport } = useSubCore()

    const Clock = dynamic(() => import("../clock"), { ssr : false });

    const { user, logout, setIsLogged, loading } = useContext(AuthContext)

    const { t } = useTranslation()

    const {
        setGames,
        setSports,
        setLocations,
        openLoginModal,
        setOpenLoginModal, 
        setTypeLogin,
        setReloadGames,
        setSelectedLocation,
        setDividends,
        popups,
    } = useSubCore()

    const { setAwaitLoadingHTTP }                                        = useCore()
    const { setSelectedBet, setSelectedIndex, setOpenAdditionalOptions } =
    useSubCore()

    const { setSelectedLeague } = useSubCore()
    // useEffect(() => 
    // {
    //     setDividends(dividend)
    // }, [ dividend ])

    useEffect(() => 
    {
        if (fixtures?.length > 0) 
        {
            setGames(fixtures)
        }

        if (sports?.length > 0) 
        {
            setSports(sports)
        }

        if (locations?.length > 0) 
        {
            setLocations(locations)
        }
    }, [ fixtures, sports, locations ])

    const checkMessages = (messages: any) => 
    {
        let count = 0

        for (let i = 0; i < messages?.length; i++) 
        {
            if (
                messages[i]?.views === 0 &&
        messages[i]?.answer &&
        messages[i]?.answer !== ""
            ) 
            {
                count += 1
            }
        }

        return count
    }

    useEffect(() => 
    {
        if (user) 
        {
            const whereCollection = [
                where("client", "==", user?.docRef),
                where("views", "==", 0),
                where("_archive", "==", false),
            ]
            const querySport      = query(
                collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
                ...whereCollection
            )

            const unsubscribe = onSnapshot(querySport, async (querySnapshot: any) => 
            {
                const docs              = querySnapshot.docs.map((doc: any) => doc.data())
                const newCounterPerView = checkMessages(docs)

                setMessageCounterAuto(newCounterPerView)
            })

            const whereCollection2 = [
                where("client", "==", user?.docRef),
                where("messageViewed", "==", true),
                where("answerViewed", "==", false),
                where("_archive", "==", false),
            ]
            const querySport2      = query(
                collection(firebaseApp.firestore, `/${getAccid()}/message/documents`),
                ...whereCollection2
            )

            const unsubscribe2 = onSnapshot(
                querySport2,
                async (querySnapshot: any) => 
                {
                    const docs = querySnapshot.docs.map((doc: any) => doc.data())
                    setMessageCounterAnswered(docs.length)
                }
            )

            return () => 
            {
                unsubscribe()
                unsubscribe2()
            }
        }
    }, [ user ])


    useEffect(() => 
    {
        if (router.asPath === "/" && !user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (popups[i]?.showBeforeLogin === true && popups[i]?.active === true) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
        else if (router.asPath === "/" && user) 
        {
            const memo = []

            for (let i = 0; i < popups?.length; i++) 
            {
                if (
                    popups[i]?.showBeforeLogin === false &&
          popups[i]?.active === true
                ) 
                {
                    memo.push(popups[i])
                }
            }

            setPopupsToShow(memo)
            setOpenPopups(true)
        }
    }, [ user, popups ])

    useEffect(() => 
    {
        const handleOutSideClick = (event: any) => 
        {
            if (!clickRef?.current?.contains(event.target)) 
            {
                setOpneSubMenu(false)
            }
        }

        window.addEventListener("mousedown", handleOutSideClick)

        return () => 
        {
            window.removeEventListener("mousedown", handleOutSideClick)
        }
    }, [ clickRef ])

    const handleLogoutClick = async () => 
    {
        setAwaitLoadingHTTP(true)
        setIsLogged(false)
        await logout()
        setSelectedBet(null)
        setSelectedIndex(null)
        setOpenAdditionalOptions(false)
        setAwaitLoadingHTTP(false)
    }

    const handleSportsClick = () => 
    {
        setSelectedLeague(null)
        setSelectedSport(null)
        setSelectedLocation(null)
        setReloadGames(true)
        redirectTo("/sports/", user, router, setOpenLoginModal)
    }

    const checkNotifications = () => 
    {
        const userCheck: any = user
        const route: any     = router.asPath

        if (route === "/deposit/?tabIndex=4" || route === "/deposit/?tabIndex=3") 
        {
            return false
        }
        else 
        {
            if (userCheck?.views_advicesUsers > 0) 
            {
                console.log("views_advicesUsers")
                return true
            }
            else if (messageCounterAuto + messageCounterAnswered > 0) 
            {
                console.log("messageCounter")
                return true
            }

            return false
        }
    }

    return (
        <>
            <header className={styles.header}>
                <div className={styles.content}>
                    <div className={styles.menuLeft}>
                        <div className={styles.logoMenu}>
                            <Link href='/' className='logo'>
                                <img 
                                    src={account?.logo?._url || logo} 
                                    alt='logo' 
                                />
                            </Link>
                        </div>

           
                    </div>
          
                    <ul className={styles.mainMenu}>

                        <div className={styles.subMenuGridItem}>
                            <CiClock1 />
                            <Clock />
                        </div>
            
                        <div className={styles.subMenuGridItem}>
                      
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=0", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/deposito_yellow.png' alt='' />
                                {t("Depósito")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                      
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=1", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/payout_yellow.png' alt='' />
                                {t("Saque")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                      
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=2", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/movimentacao_yellow.png' alt='' />
                                {t("Movimentação")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                    
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/customer_yellow.png' alt='' />
                                {t("Perguntas")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                     
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=4", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/note_yellow.png' alt='' />
                                {t("Caixa de Notificações")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                     
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/event_yellow.png' alt='' />
                                {t("Evento")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                     
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=7", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/notice_yellow.png' alt='' />
                                {t("Avisos / Regras")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                    
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=5", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/betting-history_yellow.png' alt='' />
                                {t("Histórico de Apostas")}
                            </a>
                        </div>

                        <div className={styles.subMenuGridItem}>
                      
                            <a
                                className={styles.title}
                                onClick={() => redirectTo("/deposit?tabIndex=6", user, router, setOpenLoginModal)}
                            >
                                <img src='/assets/img/cash-history_yellow.png' alt='' />
                                {t("Histórico de Cash")}
                            </a>
                        </div>
                    </ul>

          

                    {awaitLoadingHTTP === false && user && !loading ? (
                        <div className={styles.dashboarWrap}>
            
                            <button onClick={() => handleLogoutClick()}>{t("Logout")}</button>
                        </div>
                    ) : (
                        <div className={styles.menuBtnGrp}>
                            {!loading && !awaitLoadingHTTP && (
                                <>
                                    <button
                                        className={styles.buttonNavigation}
                                        data-bs-toggle='modal'
                                        data-bs-target='#signupin'
                                        onClick={() => (
                                            setOpenLoginModal(true),
                                            setTypeLogin("login"),
                                            setDisableClose(false)
                                        )}
                                    >
                                        <span>{t("Logar")}</span>
                                    </button>

                                    <button
                                        className={styles.buttonNavigationTow}
                                        data-bs-toggle='modal'
                                        data-bs-target='#signupin'
                                        onClick={() => (
                                            setOpenLoginModal(true),
                                            setTypeLogin("register"),
                                            setDisableClose(false)
                                        )}
                                    >
                                        <span className='rela'>{t("Cadastrar")}</span>
                                    </button>
                                </>
                            )}
                        </div>
                    )}
                </div>

                <div className={styles.menuBottom }>
                    <div className={router.asPath == "/sports/" ? styles.active : ""}>
                        <div className={styles.link} onClick={() => handleSportsClick()}>
                            <span>{t("Esport")}</span>
                        </div>
                    </div>

                    <div
                        className={router.asPath == "/liveSports/" ? styles.active : ""}
                    >
                        <div 
                            className={styles.link} 
                            onClick={() => redirectTo("/liveSports/", user, router, setOpenLoginModal)}
                        >
                            <span>{t("Live Sports")}</span>
                        </div>
                    </div>

                    <div>
                        <div 
                            className={styles.link}
                            onClick={() => redirectTo("/mini/lotus/baccarat/1", user, router, setOpenLoginModal)}
                        >
                            <span>{t("Mini Game")}</span>
                        </div>
                    </div>

                    <div className={router.asPath == "/casino/" ? styles.active : ""}>
                        <div 
                            className={styles.link} 
                            onClick={() => redirectTo("/casino/", user, router, setOpenLoginModal)}
                        >
                            <span>{t("Cassino")}</span>
                        </div>
                    </div>
                </div>
            </header>

            <div className={styles.headerMobile}>
                <div className={styles.contentMobile}>
                    <div className={styles.logoMenu}>
                        <Link href='/' className={styles.logo}>
                            <img
                                src={account?.logo?._url || logo}
                                alt='logo'

                            />
                        </Link>
                    </div>

                    <div className={styles.menuBtnMobile}>
                        <div className={styles.menuBtnGrp}>
                            <div className={styles.languageWrap}>
              
                            </div>

                            {openSubMenu === false ? (
                                <RxHamburgerMenu
                                    onClick={() => 
                                    {
                                        setOpneSubMenu(true)
                                    }}
                                />
                            ) : (
                                <AiOutlineClose onClick={() => setOpneSubMenu(false)} />
                            )}
                        </div>
                    </div>
                </div>
                {!user && (
                    <div className={styles.loginHeaderButtons}>
                        <button
                            className={styles.buttonNavigation}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("login"),
                                setDisableClose(false)
                            )}
                        >
                            <CiLogin />
                            <span>{t("Logar")}</span>
                        </button>

                        <button
                            className={styles.buttonNavigationTow}
                            data-bs-toggle='modal'
                            data-bs-target='#signupin'
                            onClick={() => (
                                setOpenLoginModal(true),
                                setTypeLogin("register"),
                                setDisableClose(false)
                            )}
                        >
                            <FaUser />
                            <span className='rela'>{t("Cadastrar")}</span>
                        </button>
                    </div>
                )}
                {openSubMenu && (
                    <ul className={styles.mainMenuMobile} ref={clickRef}>
                        <li>
                            <Link
                                href='/sports'
                                className={pagePath == "/sports" ? styles.active : ""}
                                onClick={() => 
                                {
                                    setSelectedSport(null); setReloadGames("true")
                                }}
                            >
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Esportes")}
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link href='/liveSports'>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Live Sports")}
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link href='/mini/lotus/baccarat/1'>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Mini Game")}
                                </span>
                            </Link>
                        </li>

                        <li>
                            <Link href='/casino'>
                                <span onClick={() => setOpneSubMenu(false)}>
                                    {t("Cassino")}
                                </span>
                            </Link>
                        </li>

                        {user !== null ? (
                            <div className={styles.dashboarWrap}>
                                <button
                                    onClick={() => (handleLogoutClick(), setOpneSubMenu(false))}
                                >
                                    {t("Logout")}
                                </button>
                            </div>
                        ) : (
                            <div className={styles.btns}>
                                <button

                                    className={styles.buttonNavigation}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("login"),
                                        setDisableClose(false),
                                        setOpneSubMenu(false)
                                    )}
                                >
                                    <span>{t("Logar")}</span>
                                </button>

                                <button

                                    className={styles.buttonNavigationTow}
                                    data-bs-toggle='modal'
                                    data-bs-target='#signupin'
                                    onClick={() => (
                                        setOpenLoginModal(true),
                                        setTypeLogin("register"),
                                        setDisableClose(false),
                                        setOpneSubMenu(false)
                                    )}
                                >
                                    <span className='rela'>{t("Cadastrar")}</span>
                                </button>
                            </div>
                        )}
                    </ul>
                )}
            </div>
            {openLoginModal && (
                <ModalLogin
                    closeModal={setOpenLoginModal}

                    banks={banks}
                    disableClose={disableClose}
                    setDisableClose={setDisableClose}

                />
            )}

            {openPopups && (
                <PopupsModal
                    open={openPopups}
                    setOpen={setOpenPopups}
                    popups={popupsToShow}
                />
            )}

            {!openPopups && user && checkNotifications() && (
                <NotificationPopup
                    countMessages={messageCounterAuto + messageCounterAnswered}
                    notifications={user?.views_advicesUsers}
                />
            )}
        </>
    )
}
