import styles from "./styles.module.scss"
import { useContext, useEffect, useState } from "react"
import { ModalVideo } from "../../ModalVideo"
import Link from "next/link"
import { AuthContext } from "../../context/AuthContext"
import { useCore } from "@/core-nextv3/core/core"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"
import { getGameUrl } from "@/core-nextv3/ximax2/ximax2.api"
import { THEME_SETTING } from "@/setting/setting"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import { useRouter } from "next/router"

export const CardSlotProvider = ({ provider }: any) => 
{
    const [ gameUrl, setGameUrl ] = useState()

    const { user }                                                                    = useContext(AuthContext)
    const { setAwaitLoadingHTTP, selectedLanguage }                                   = useCore()
    const { setOpenMessageModal, setMessage, setMessageModalIcon, setOpenLoginModal } = useSubCore()
    const { t }                                                                       = useTranslation()
    const router                                                                      = useRouter()

    async function getGameUrlPage(gameId: string) 
    {
     
        const setting:any = {
            currency : "KRW",
            gameId   : gameId,
            lang     : "kr",
            document : { referencePath : user?.referencePath } 
        }
        const result      = await getGameUrl(setting)

        if (result?.status && result?.data?.returnCode === 0)
        {
            return result?.data?.gameUrl
        }
        else 
        {
            const messageModal = t("Cassino em manutenção")
            setMessage(messageModal)
            setMessageModalIcon("warning")
            setOpenMessageModal(true)
        }
    }

    async function openGame(gameId: string) 
    {
        const allowCasino = (user as any)?.allowCasino

        if (allowCasino !== false) 
        {

            if (THEME_SETTING.openExternalsInNewTab)
            {
                const gameUrl = await getGameUrlPage(gameId)

                window.open(gameUrl, "_blank");
                return
            }

            setAwaitLoadingHTTP(true)
          
            
            setGameUrl(gameUrl)
            setAwaitLoadingHTTP(false)
        }
        else 
        {
            setMessage("Usuário não possui permissão para realizar apostas em casino")
            setOpenMessageModal(true)
        }
    }

    return (
        <>
            <div className={`${styles.cardLive} ${styles.hoverEffect}`}>
                <div className={styles.cardLiveBox}>
                    <a
                        onClick={() => redirectTo(`/slotsPage/${provider?.name}`, user, router, setOpenLoginModal) }
                    >
                        {/* <img alt={game.tpName} src={getImage(game)} title={game.tKR} /> */}
                        <img
                            // src={
                            //     provider?.image._480x480
                            //         ? provider?.image?._480x480
                            //         : "/assets/img/cassino_placeholder.png"
                            // }
                            src={provider?.image?._url ?? "/assets/img/slotdefault.png"}
                            alt={provider?.image?.name}
                        />
                        {/* <div className={styles.cardLiveBoxLabel}>
                            {selectedLanguage === "pt" ? game?.name : game?.title_kr}
                        </div> */}
                    </a>
                </div>

            </div>
        </>
    )
}
