export const betNameParser = (betName) => {

    switch (betName) {
        case "P":
            return "PLAYER";
        case "PP":
            return "PLAYER PAIR";
        case "B":
            return "BANKER";
        case "BB":
            return "BANKER PAIR";
        case "T":
            return "TIE";
        case "Even":
            return "Par";
        case "Odd":
            return "Impar";
        case "Under":
            return "Under";
        case "Over":
            return "Over";
        case "Black":
            return "Preto";
        case "Red":
            return "Vermelho";
        case "Clubs":
            return "Paus";
        case "Diamonds":
            return "Ouros";
        case "Spades":
            return "Espadas";
        case "Hearts":
            return "Copas";
        case "Left":
            return "Esquerda";
        case "Right":
            return "Direita";
        case "3":
            return "3";
        case "4":
            return "4";
        case "Left_3_Even":
            return "좌 + 3 + 짝";
        case "Right_3_Odd":
            return "우 + 3 + 홀";
        case "Left_4_Odd":
            return "좌 + 4 + 홀";
        case "Right_4_Even":
            return "우 + 4 + 짝";
        case "PowerOdd":
            return "파워볼 홀";
        case "PowerEven":
            return "파워볼 짝";
        case "PowerUnder":
            return "파워볼 언더";
        case "PowerOver":
            return "파워볼 우더";
        case "High":
            return "Alto";
        case "Medium":
            return "Médio";
        case "Low":
            return "Baixo";
        default:
            return betName;
    }
}