import Types from "../type";
import { ISetting } from "../util/setting";
import { call } from "../util/call.api";
import { useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";

const useRealTime = (firebaseApp:any, referencePath:string, onSuccess:any) => 
{
    useEffect(() => 
    {
        // trata referencia undefined
        if (!referencePath)
        {
            return
        }

        const unsubscribe = onSnapshot(
            doc(
                firebaseApp.firestore,
                referencePath
            ),
            async (docRef) => 
            {

                const data : any = docRef.data();
                //console.log("realtime", data)

                onSuccess(data)						
            }
        )

        return () => 
        {
            unsubscribe();
        };
			
    }, [ referencePath ]);	
}

const useRealTimeCall = (firebaseApp:any, referencePath:string, setting:ISetting, onSuccess:any) => 
{
    useEffect(() => 
    {
        let firtTime = false;

        // trata referencia undefined
        if (!referencePath)
        {
            return
        }

        const unsubscribe = onSnapshot(
            doc(
                firebaseApp.firestore,
                referencePath
            ),
            async (docRef) => 
            {

                if (firtTime)
                {
                    call(Types.GET_DOCUMENT_SERVER, setting.merge(
                        {
                            document : {
                                referencePath : referencePath
                            }
                        })).then((result:any) => 
                    {
                        onSuccess(result.data);		
                    });
                }		
						
                firtTime = true;
            }
        )

        return () => 
        {
            unsubscribe();
        };
			
    }, [ referencePath ]);	
}

export { 
    useRealTime,
    useRealTimeCall
}