import Types from "../type"
import { ISetting, setting } from "../util/setting"
import { call } from "../util/call.api"
import { useEffect } from "react"
import { useRouter } from "next/router"
import { useCore } from "../core/core"

const useGetBetCart = (setting: ISetting, onSuccess: any) => 
{
    const router               = useRouter()
    const { setIsLoadingCart } = useCore()

    useEffect(() => 
    {
        setIsLoadingCart(false)

        call(Types.GET_BET_CART_SERVER, setting).then((result) => 
        {
            onSuccess(result.data)

            setIsLoadingCart(true)
        })    
    }, [ router.asPath ])
}


/*
const useMergeCart = (setting:ISetting, onSuccess:any) =>  
{
	const router   = useRouter();
	const { cart } = useCore();

	useEffect(() => 
	{
		if(router.query.document && cart)
		{
			call(Types.MERGE_CART_SERVER, setting.merge(
			{
				document : {
					referencePath : router.query.document
				}
			})).then((result) => 
			{
				onSuccess(result.data);
			});
		}

	}, [router.query, cart]);
}

const useSetStoreCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_STORE_SERVER, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const useSetCouponCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_COUPON_CART, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const useSetSellerCart = (setting:ISetting, onSuccess?:any) => 
{
	const router = useRouter();	
	
	useEffect(() => 
	{
		call(Types.SET_SELLER_CART, setting).then((result) => 
		{
			if(onSuccess)
			{
				onSuccess(result.data)
			}			
		});	
	}, [router.asPath]);
}

const mergeCart = async (setting: ISetting) => 
{
	const result = await call(Types.MERGE_CART_SERVER, setting);
	return result;
};

const mergeAllCart = async (setting: ISetting) => 
{
	const result = await call(Types.MERGE_ALL_CART_SERVER, setting);
	return result;
};

const validateCart = async (setting: ISetting) => 
{
	const result = await call(Types.VALIDATE_CART_SERVER, setting);
	return result;
};

const setInstallmentCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_INSTALLMENT_CART_SERVER, setting);
	
	return result;
}

const setInstallmentMethods = async (setting:ISetting) =>
{
	const result = await call(Types.SET_INSTALLMENT_METHODS_SERVER, setting);

	return result;
}
*/

const checkoutBetCart = async (setting: ISetting) => 
{
    const result = await call(Types.CHECKOUT_BET_CART_SERVER, setting)

    return result
}

const setTotalBetCart = async (setting: ISetting) => 
{
    const result = await call(Types.SET_TOTAL_BET_CART_SERVER, setting)

    return result
}

const reloadBetCartItems = async (setting: ISetting) => 
{
    const result = await call(Types.RELOAD_BET_CART_ITEMS_SERVER, setting)

    return result
}

const directAddPoints = async (setting: ISetting) => 
{
    const result = await call(Types.DIRECT_ADD_POINTS_SERVER, setting)
    
    return result
}

const approveDeposit = async (setting: ISetting) => 
{
    const result = await call(Types.APPROVE_DEPOSIT_BET_SERVER, setting)

    return result
}

const rejectDeposit = async (setting: ISetting) => 
{
    const result = await call(Types.REJECT_DEPOSIT_BET_SERVER, setting)

    return result
}

const resetBet = async (setting: ISetting) => 
{
    const result = await call(Types.RESET_BET_SERVER, setting)

    return result
}

const resetBetItem = async (setting: ISetting) => 
{
    const result = await call(Types.RESET_BET_ITEM_SERVER, setting)

    return result
}

const resetMinigameBet = async (setting: ISetting) => 
{
    const result = await call(Types.RESET_MINIGAME_BET_SERVER, setting)

    return result
}

const getBetStatistics = async (setting: ISetting) => 
{
    const result = await call(Types.GET_BET_STATISTICS_SERVER, setting)

    return result
}

const getProfitChartData = async (setting: ISetting) => 
{
    const result = await call(Types.GET_BET_PROFIT_CHART_DATA_SERVER, setting)

    return result
}

const authorizeUser = (setting: ISetting) => 
{
    const result = call(Types.AUTHORIZE_BET_USER_SERVER, setting)

    return result
}

const unauthorizeUser = (setting: ISetting) => 
{
    const result = call(Types.UNAUTHORIZE_BET_USER_SERVER, setting)

    return result
}

/*
const setCouponCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_COUPON_CART, setting);
	
	return result;
}

const delCouponCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_COUPON_CART, setting);
	
	return result;
}

const setDiscountClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_DISCOUNT_CLIENT_CART, setting);
	
	return result;
}

const delDiscountClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_DISCOUNT_CLIENT_CART, setting);
	
	return result;
}

const setAddressCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ADDRESS_CART_SERVER, setting);
	
	return result;
}

const setShippingMethodCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_SHIPPING_METHOD_CART_SERVER, setting);
	
	return result;
}

const setPaymentMethodCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_PAYMENT_METHOD_CART_SERVER, setting);
	
	return result;
}

const setCreditCardCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_CREDITCARD_CART_SERVER, setting);
	
	return result;
}

const setAttachmentCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ATTACHMENT_CART_SERVER, setting);
	
	return result;
}
*/

const clearCart = async (setting: ISetting) => 
{
    const result = await call(Types.CLEAR_BET_CART_SERVER, setting)

    return result
}

const setItemCart = async (setting: ISetting) => 
{
    const result = await call(Types.SET_ITEM_BET_CART_SERVER, setting)

    return result
}

const setMinigameItemCart = async (setting: ISetting) => 
{
    const result = await call(Types.SET_MINIGAME_ITEM_BET_CART_SERVER, setting)

    return result
}

const setBetBonusCart = async (setting: ISetting) => 
{
    const result = await call(Types.SET_BONUS_BET_CART_SERVER, setting)

    return result
}
/*
const setPackaging = async (setting:ISetting) => 
{
	const result = await call(Types.SET_PACKAGING_CART_SERVER, setting);
	
	return result;
}

const setGiftCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_GIFT_CART_SERVER, setting);
	
	return result;
}

const setItemsCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ITEMS_CART_SERVER, setting);
	
	return result;
}

const setClientCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_CLIENT_CART_SERVER, setting);
	
	return result;
}

const setReferrerCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_REFERRER_CART_SERVER, setting);
	
	return result;
}

const setDiscountCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_DISCOUNT_CART_SERVER, setting);
	
	return result;
}

const setSellerCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_SELLER_CART_SERVER, setting);
	
	return result;
}
*/

const delItemCart = async (setting: ISetting) => 
{
    const result = await call(Types.DEL_ITEM_BET_CART_SERVER, setting)

    return result
}

const delBetBonusCart = async (setting: ISetting) => 
{
    const result = await call(Types.DEL_BONUS_BET_CART_SERVER, setting)

    return result
}

const cancelBet = async (setting: ISetting) => 
{
    const result = await call(Types.CANCEL_BET_SERVER, setting)
    return result
}

const addTransfer = async (setting: ISetting) => 
{
    const result = await call(Types.TRANSFER_BET_SERVER, setting)
    return result
}

const addPointsTransfer = async (setting: ISetting) => 
{
    const result = await call(Types.POINTS_TRANSFER_BET_SERVER, setting)
    return result
}

const processTransfer = async (setting: ISetting) => 
{
    const result = await call(Types.PROCESS_TRANSFER_BET_SERVER, setting)
    return result

}

const startProcessTransfer = async (setting: ISetting) => 
{
    const result = await call(Types.START_PROCESSING_TRANSFER_BET_SERVER, setting)
    return result
}

const startProcessWithdrawal = async (setting: ISetting) => 
{
    const result = await call(Types.START_PROCESSING_WITHDRAWAL_BET_SERVER, setting)
    return result
}

const todayStatistics = async (setting: ISetting) =>
{
    const result = await call(Types.TODAY_STATISTICS_BET_SERVER, setting)
    return result
}

const addDeposit = async (setting: ISetting) => 
{
    const result = await call(Types.DEPOSIT_BET_SERVER, setting)
    return result
}

const addWithdrawal = async (setting: ISetting) => 
{
    const result = await call(Types.WITHDRAW_BET_SERVER, setting)
    return result
}

const addWithdrawPartner = async (setting: ISetting) => 
{
    const result = await call(Types.WITHDRAW_PARTNER_SERVER, setting)
    return result
}

const approveWithdrawal = async (setting: ISetting) => 
{
    const result = await call(Types.APPROVE_WITHDRAWAL_BET_SERVER, setting)
    return result
}

const cancelWithdrawal = async (setting: ISetting) => 
{
    const result = await call(Types.CANCEL_WITHDRAWAL_BET_SERVER, setting)
    return result
}

const cancelWithdrawalPartner = async (setting: ISetting) => 
{
    const result = await call(Types.CANCEL_WITHDRAWAL_PARTNER_SERVER, setting)
    return result
}

const hideHistory = async (setting: ISetting) => 
{
    const result = await call(Types.HIDE_HISTORY_BET_SERVER, setting)
    return result
}

const rollbackDeposit = async (setting: ISetting) => 
{
    const result = await call(Types.ROLLBACK_DEPOSIT_BET_SERVER, setting)
    return result
}

const settleBet = async (setting: ISetting) => 
{
    const result = await call(Types.SETTLE_BET_SERVER, setting)
    return result
}

const settleMinigameBet = async (setting: ISetting) => 
{
    const result = await call(Types.SETTLE_MINIGAME_BET_SERVER, setting)
    return result
}

const verifyMinigameBet = async (setting: ISetting) => 
{
    const result = await call(Types.VERIFY_MINIGAME_BET_SERVER, setting)
    return result
}

const calculateDepositPartner = async (setting: ISetting) => 
{
    const result = await call(Types.CALCULATE_DEPOSIT_PARTNER_SERVER, setting)
    return result
}

const setFixture = async (setting: ISetting) => 
{
    const result = await call(Types.SET_FIXTURE_SERVER, setting)
    return result
}

const completeTurnover = async(setting:any) => 
{
    const result = await call(Types.COMPLETE_TURNOVER_SERVER, setting)
    return result
}

const processWithdraw = async (setting: ISetting) =>
{
    const result = await call(Types.PROCESS_WITHDRAW_SERVER, setting)
    return result
}

const getUserPayback = async (setting: ISetting) =>
{
    const result = await call(Types.USER_PAYBACK_SERVER, setting)
    return result
}

const paybackWithdraw = async (setting: ISetting) =>
{
    const result = await call(Types.PAYBACK_WITHDRAW_SERVER, setting)
    return result
}

const getBonuses = async (setting: ISetting) =>
{
    const result = await call(Types.GET_DEPOSIT_BONUSES_SERVER, setting)
    return result
}

const approvePaybackWithdraw = async (setting: ISetting) =>
{
    const result = await call(Types.APPROVE_PAYBACK_WITHDRAW_SERVER, setting)
    return result
}

const cancelPaybackWithdraw = async (setting: ISetting) =>
{
    const result = await call(Types.CANCEL_PAYBACK_WITHDRAW_SERVER, setting)
    return result
}

const processDirectPoints = async (setting: ISetting) =>
{
    const result = await call(Types.PROCESS_DIRECT_POINTS_SERVER, setting)
    return result
}

const createSubpartner = async (setting: ISetting) =>
{
    const result = await call(Types.CREATE_SUBPARTNER_SERVER, setting)
    return result
}

const partnerCommision = async (setting: ISetting) => 
{
    const result = await call(Types.PARTNER_COMMISION_SERVER, setting)
    return result
}

const answerMessage = async (setting: ISetting) => 
{
    const result = await call(Types.MESSAGE_ANSWER_SERVER, setting)
    return result
}

/**
 * Envia um mensagem para o admin
 * @param setting 
 * @returns 
 */
const sendMessage = async (setting: ISetting) => 

{
    const result = await call(Types.SEND_MESSAGE_SERVER, setting) 
    return result
}

const addCasinoGame = async (setting: any) =>
{
    const result = await call(Types.ADD_CASINO_GAME_SERVER, setting)
    return result
}

const getUserGamesCount = async (setting: any) => {
    const result = await call(Types.USER_GAME_COUNT_SERVER, setting)
    return result
}
/*
const delGiftCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_GIFT_CART_SERVER, setting);
	
	return result;
}

const delItemsByStoreCart = async (setting:ISetting) => 
{
	const result = await call(Types.DEL_ITEMS_BY_STORE_CART_SERVER, setting);
	
	return result;
}

const setAddressReferenceCart = async (setting:ISetting) => 
{
	const result = await call(Types.SET_ADDRESS_REFERENCE_CART_SERVER, setting);
	
	return result;
}

const setCreditCardReferenceCart = async (setting: ISetting) => {
	const result = await call(Types.SET_CREDITCARD_REFERENCE_CART_SERVER, setting);
  
	return result;
};

const calculateZipCodeCart = async (setting: ISetting) => {
	const result = await call(Types.CALCULATE_ZIP_CODE_SERVER, setting);
  
	return result;
};
*/

export {
    useGetBetCart,
    // mergeCart,
    // useSetStoreCart,
    // setAddressReferenceCart,
    // setCreditCardReferenceCart,
    // setItemsCart,
    delItemCart,
    checkoutBetCart,
    clearCart,
    getBetStatistics,
    getProfitChartData,
    // setAddressCart,
    // setInstallmentCart,
    // setInstallmentMethods,
    setTotalBetCart,
    reloadBetCartItems,
    setItemCart,
    setBetBonusCart,
    delBetBonusCart,
    addTransfer,
    addPointsTransfer,
    processTransfer,
    addDeposit,
    approveDeposit,
    directAddPoints,
    rejectDeposit,
    addWithdrawal,
    approveWithdrawal,
    cancelWithdrawal,
    hideHistory,
    setMinigameItemCart,
    rollbackDeposit,
    settleBet,
    authorizeUser,
    unauthorizeUser,
    todayStatistics,
    // setShippingMethodCart,
    // setAttachmentCart,
    // setPaymentMethodCart,
    // setCreditCardCart,
    // setCouponCart,
    // setDiscountClientCart,
    // delDiscountClientCart,
    // validateCart,
    // calculateZipCodeCart,
    // delItemsByStoreCart,
    // setSellerCart,
    // setClientCart,
    // setDiscountCart,
    // delCouponCart,
    // useMergeCart,
    // mergeAllCart,
    // setGiftCart,
    // delGiftCart,
    // setReferrerCart,
    // useSetCouponCart,
    // useSetSellerCart,
    // setPackaging,
    cancelBet,
    resetBet,
    resetBetItem,
    resetMinigameBet,
    verifyMinigameBet,
    settleMinigameBet,
    addWithdrawPartner,
    cancelWithdrawalPartner,
    calculateDepositPartner,
    setFixture,
    completeTurnover,
    processWithdraw,
    paybackWithdraw,
    getBonuses,
    approvePaybackWithdraw,
    cancelPaybackWithdraw,
    getUserPayback,
    processDirectPoints,
    startProcessTransfer,
    startProcessWithdrawal,
    createSubpartner,
    partnerCommision,
    answerMessage,
    addCasinoGame,
    getUserGamesCount,
    sendMessage
}
