import { useCore } from "@/core-nextv3/core/core"
import styles from "./styles.module.scss"
import { format, utcToZonedTime } from "date-fns-tz"
import { useSubCore } from "@/hooks/useSubCore"
import { useRouter } from "next/router"
import { fromUnixTime } from "date-fns"
import { useContext } from "react"
import { AuthContext } from "../../../context/AuthContext"
import redirectTo from "@/bet-nextv1/utils/redirectTo"

export const HighlightGame: React.FC<any> = ({ data }) => 
{
    const { setAwaitLoadingHTTP, selectedLanguage } = useCore()

    let date: string;

    if (typeof data.startDate === "object") 
    {
        const zonedDateTime: any = utcToZonedTime(fromUnixTime(data.startDate._seconds), "Asia/Seoul");
        date                     = format(zonedDateTime, "HH:mm");
    }
    else 
    {
        const zonedDateTime: any = utcToZonedTime(data.startDate, "Asia/Seoul");
        date                     = format(zonedDateTime, "HH:mm");
    }

    const router = useRouter()

    const { user } = useContext(AuthContext)

    const {
        setSelectedLeague,
        setSelectedLocation,
        setSelectedSport,
        setReloadGames,
        setOpenLoginModal,
        setVirtual,
        setEndDate,
        setSelectedId
    } = useSubCore()


    const handleSelectGame = async () => 
    {
        setAwaitLoadingHTTP(true)


        setSelectedSport(data.sport.id)
        setVirtual(undefined)

        setSelectedLeague(data.league)
        setSelectedId(data.id)


        setEndDate(null)
        setSelectedLocation(null)

        setReloadGames(true)

        if (router?.asPath !== "/sports/") 
        {
            redirectTo("/sports", user, router, setOpenLoginModal)
        }

        setAwaitLoadingHTTP(false)
    }

    return <div className={styles.content} onClick={() => 
    {
        handleSelectGame() 
    }}>
        <div className={styles.sportIcon}><img src={data?.sport?.icon?._url} alt=""></img></div>

        <div className={styles.participantsName}>
            <div className={styles.date}>{date}</div>
            {data.participants?.map((participant: any) => (
                <div key={participant.name} className={styles.participantContainer}>
                    <img src={participant.flag ? participant.flag._url : "/assets/img/flags/default.png"} alt=""></img>
                    <span>
                        {selectedLanguage === "pt"
                            ? participant.name_br
                            : participant.name_ko}
                    </span></div>))}
        </div>
    </div>
}