import styled, { createGlobalStyle } from "styled-components";

// AQUI ESTÁ ALGUMAS CLASSES UTEIS GLOBAIS
// TODA CLASSE GLOBAL, INICIA O A LETRA "g" (global)
const GlobalClasses = createGlobalStyle`
// z-index
  .gz-index-1 {
    z-index: 1;
  }
  .gz-index-2 {
    z-index: 2;
  }

  // display
  .gd-flex {
    display: flex;
  }
  .gflex-1 {
    flex: 1;
  }
  .gjustify-content-between {
    justify-content: space-between;
  }
  .gjustify-content-center{
    justify-content: center;
  }
  .galign-items-center {
    align-items: center;
  }
  .gflex-1 {
    flex: 1;
  }
  .gflex-2 {
    flex: 2;
  }
  .gflex-3 {
    flex: 3;
  }
  .gflex-4 {
    flex: 4;
  }


  // spacing
  .ggap-5 {
    gap: 5px;
  }
  .ggap-6 {
    gap: 6px;
  }
  .ggap-8 {
    gap: 8px;
  }

  .gp-2 {
    padding: 2px;
  }

  .gp-5 {
    padding: 5px;
  }

  // font-weight
  .gfont-weight-700 {
    font-weight: 700;
  }  

  // height
  .gh-auto {
    height: auto;    
  } 


  // positions
  .gposition-absolute {
    position: absolute;
  }


 
`;

export default GlobalClasses;
