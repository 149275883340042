import { useState, useLayoutEffect, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

//https://www.digitalocean.com/community/tutorials/default-sizes-for-twitter-bootstraps-media-queries

const useResponsive = () => 
{
    const [isClient, setIsClient] = useState(false);

    const isMobile = useMediaQuery({
        minWidth: 480 + 1
    });

    const isTablet = useMediaQuery({
        minWidth: 768 + 1
    });

    const isDesktop = useMediaQuery({
        minWidth: 992 + 1 // +1 para o css
    });

    useEffect(() => {
        setIsClient(true);
    }, []);

    // useLayoutEffect(() => {
    //   if (typeof window !== 'undefined') setIsClient(true);
    // }, []);

    return {
        isMobile  : isClient ? isMobile  : false,
        isDesktop : isClient ? isDesktop : false,
        isTablet  : isClient ? isTablet  : false,
        isClient
    };
};

const Desktop = ({ children }:any) => 
{
    const [isClient, setIsClient] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 992 });

    useEffect(() => {
        setIsClient(true);
    }, []);

    return isClient && isDesktop ? children : null;
};

const Mobile = ({ children }:any) => 
{
    const [isClient, setIsClient] = useState(false);
    const isMobile = useMediaQuery({ maxWidth: 991 });

    useEffect(() => {
        setIsClient(true);
    }, []);
    
    return isClient && isMobile ? children : null;
};

export {
    useResponsive,
    Desktop,
    Mobile
};