import { AiOutlineClose } from 'react-icons/ai'
import logo from '../../../public/assets/img/logo.png'
import styles from './styles.module.scss'
import Image from 'next/image'
import { customImageLoader } from '@/core-nextv3/util/util'

export const HeaderForMobileMenu = ({ closeFunction }: any) => {
  return (
    <div className={styles.navigationMobileHeading}>
      <div className={styles.logoMenu}>
        <Image src={logo} alt='logo' loader={customImageLoader} />
      </div>

      <div className={styles.iconClose}>
        <AiOutlineClose onClick={() => closeFunction(false)} />
      </div>
    </div>
  )
}
