import "@/styles/globals.scss"
import AppPageTwo from "@/bet-nextv1/AppPageTwo";
import user from "@/core-nextv3/model/user";
import { THEME_SETTING } from "@/setting/setting";
import { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import { AuthContext } from "@/bet-nextv1/context/AuthContext";
import { AnimatedMenu } from "@/components/AnimatedMenu";
import Head from "next/head";

function MyApp({ Component, pageProps }: any) 
{
    return (
        <>
            <Head>
                <title>고니 카지노</title>
            </Head>
            <AppPageTwo Component={Component} MenuComponent={AnimatedMenu} pageProps={pageProps} />
        </>
    )
 
    
}

export default MyApp;
