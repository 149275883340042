import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Box, Link } from "@mui/material"
import styles from "./styles.module.scss"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import { AuthContext } from "@/bet-nextv1/context/AuthContext"
import router, { useRouter } from "next/router"
import { useContext, useEffect, useState } from "react"
import { useSubCore } from "@/hooks/useSubCore"
import { IoMdClose } from "react-icons/io"
import { useTranslation } from "next-i18next"
import { countOwnerDocument } from "@/core-nextv3/document/document.api"
import { MESSAGE_SETTING } from "@/setting/setting"

export const DrawerRight = ({ toggleDrawer, bannersGames }) => 
{
    const { user }                          = useContext<any>(AuthContext)
    const router                            = useRouter()
    const { setOpenLoginModal }             = useSubCore()
    const { t }                             = useTranslation()
    const [ messageCount, setMessageCount ] = useState(0)

    

    const handleclick = (route: string) => 
    {
        toggleDrawer(false)
        redirectTo(route, user, router, setOpenLoginModal)
    }
    
    useEffect(() => 
    {
        const getMessageCount = async () => 
        {
            const result = await countOwnerDocument(
                MESSAGE_SETTING.merge({
                    ownerField : "client",
                    orderBy    : "postdate",
                    asc        : false,
                    where      : [
                        {
                            field    : "_archive",
                            operator : "==",
                            value    : false,
                        },
                    ],
                })
            )

            if (result?.status) 
            {
                setMessageCount(result?.count)
            }
            else 
            {
                setMessageCount(0)
            }
        } 
    }, [])

    
    return <Box sx={{ width : 300 }} className={styles.content} role="presentation">
        <div className={styles.topHeader}>
            <span className={styles.name}>{user?.name}</span>
            <div className={styles.link}>
                {/* <a
                    className={styles.title}
                    onClick={() => redirectTo("/deposit?tabIndex=3", user, router, setOpenLoginModal)}
                >
                    <img src='/assets/img/customer.png' alt='' />
                    {t("Dinheiro do Cassino")}{":"}
                    <small>
                        &nbsp;{ +messageCount}
                    </small>
                </a> */}
                <IoMdClose onClick={() => toggleDrawer(false)} />
            </div>
            
        </div>
        <List className={styles.drawerList}>
            <ListItem className={styles.item}>
                <div className={styles.bannersGames}>
                    <a onClick={() => handleclick("/liveCasino")}><img src={bannersGames?.[0]?.mobileImage?._url}  alt="" /></a>
                    <a onClick={() => handleclick("/slotsCasino")}><img src={bannersGames?.[1]?.mobileImage?._url} alt="" /></a>
                </div>
            </ListItem>
            <Divider />
        </List>
    </Box>
}