import user from "@/core-nextv3/model/user"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import casino from "@/pages/casino"
import { t } from "i18next"
import router from "next/router"
import { FaPlus } from "react-icons/fa"
import { CardLive } from "../../bet-nextv1/CasinoComponents/CardLive"
import { CardSlotProvider } from "../../bet-nextv1/CasinoComponents/CardSlotProvider"
import redirectTo from "../../bet-nextv1/utils/redirectTo"
import styles from "./styles.module.scss"
import { useSubCore } from "@/hooks/useSubCore"
import { useTranslation } from "next-i18next"
import { TabList, TabPanels, TabPanel, Box, Button, useMultiStyleConfig, useTab } from "@chakra-ui/react"
import { Tabs, Tab } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { THEME_SETTING } from "@/setting/setting"

export const LinksTabs = ({ casino, slot, providers, deactivatedcasino }:any) => 
{
    const { isDesktop }         = useResponsive()
    const { setOpenLoginModal } = useSubCore()
    const { t }                 = useTranslation()
    
    const CustomTab = React.forwardRef((props, ref) => 
    {
        const tabProps   = useTab({ ...props, ref })
        const isSelected = !!tabProps["aria-selected"]

        return (
            <Button className={isSelected ? `${styles.tabLinks} ${styles.selected}` : styles.tabLinks } {...tabProps}>
                <Box  as='span' mr='2'>
                </Box>
                {tabProps.children}
            </Button>
        )
    })
    
    return <>
        <Tabs>
            <TabList>
                <CustomTab>
                    카지노 <span>CASINO</span> {!THEME_SETTING.hideIconOnHometabs ? <img src={"/assets/img/casinocash.png"}></img> : <div className={styles.filler}></div>}
                </CustomTab>
                <CustomTab>
                    슬롯게임 <span>SLOT GAME</span> {!THEME_SETTING.hideIconOnHometabs ? <img src={"/assets/img/slot.png"}></img> : <div className={styles.filler}></div>}
                </CustomTab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <div className={styles.cardsCasino}>
                        {casino?.map((lobby: any) => (
                            lobby?.status && <div className={(lobby?.maintenance || deactivatedcasino) ? styles.noAnimation : ""} key={lobby.id}><CardLive game={lobby} maintenance={(lobby?.maintenance || deactivatedcasino)}/></div>
                        ))}
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className={styles.items}>
                        {providers ?.map(
                            (provider: any) =>
                                provider?.status && <CardSlotProvider provider={provider} key={provider.id} />
                        )}
                    </div>
                </TabPanel>
            </TabPanels>
        </Tabs>
    </>
}