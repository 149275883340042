import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

// ** React Imports
import { createContext, ReactNode } from "react"

// ** Types
import { FirebaseType } from "./types"

// ** Defaults
const defaultProvider: FirebaseType = {
    auth      : null,
    firestore : null,
    app       : null,
}

const FirebaseContext = createContext(defaultProvider)

type Props = {
  children: ReactNode
}

const FirebaseProvider = ({ children }: Props) => 
{
    const firebaseConfig = {
        apiKey            : process.env.FIREBASE_API_KEY,
        authDomain        : process.env.FIREBASE_AUTH_DOMAIN,
        projectId         : process.env.FIREBASE_PROJECT,
        storageBucket     : process.env.STORAGE_BUCKET,
        messagingSenderId : process.env.MESSAGING_SENDER_ID,
        appId             : process.env.FIREBASE_APP_ID,
    }

    const app       = initializeApp(firebaseConfig)
    const firestore = getFirestore(app)
    const auth      = getAuth(app)

    const values = {
        auth      : auth,
        firestore : firestore,
        app       : app
    }

    return (
        <FirebaseContext.Provider value={values}>
            {children}
        </FirebaseContext.Provider>
    )
}

export { FirebaseContext, FirebaseProvider }
