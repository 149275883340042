import styles from "./styles.module.scss"
import { BsArrowRightShort, BsFillTrophyFill } from "react-icons/bs"
import { AccordionSport } from "./AccordionSport"
import { AccordionSports } from "./AccordionSports"
import { AccordionSportItem } from "./AccordionSportItem"
import { useResponsive } from "@/core-nextv3/util/useResponsive"
import { useSubCore } from "@/hooks/useSubCore"
import { useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { useCore } from "@/core-nextv3/core/core"
import { doc, onSnapshot } from "firebase/firestore"
import { FirebaseContext } from "@/bet-nextv1/context/FirebaseContext"
import { HeaderForMobileMenu } from "@/bet-nextv1/HeaderForMobileMenu/index,"
import { useTranslation } from "next-i18next"
import { HighlightsWidget } from "@/bet-nextv1/HighlightsWidget"
import { AuthContext } from "@/bet-nextv1/context/AuthContext"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import { getFlag } from "@/bet-nextv1/utils/getFlag"
import { CiViewList } from "react-icons/ci"
import { THEME_SETTING } from "@/setting/setting"

export const AnimatedMenu = ({
    closePopularEvents,
    openModalPopularEvents,
    account
}: any) => 
{
    const [ navigation, setNavigation ] = useState<any>()

    const {
        games,
        locations,
        sports,
        setLeagueGames,
        setSelectedLeague,
        setSelectedLocation,
        setSelectedSport,
        setTotalGames,
        setReloadGames,
        setVirtual,
        setEndDate,
        setStartDate,
        setFilterPerLeague,
        setOpenLoginModal
    } = useSubCore()
    const { isDesktop }                             = useResponsive()
    const router                                    = useRouter()
    const { setAwaitLoadingHTTP, selectedLanguage } = useCore()
    const firebaseApp                               = useContext(FirebaseContext)
    const [ toggle, setToggle ]                     = useState(false)
    const { t }                                     = useTranslation()

    const { user } = useContext(AuthContext)

    useEffect(() => 
    {
        onSnapshot(
            doc(firebaseApp.firestore, "default/fixtureMenu/flatFixtureMenu/default"),
            async (querySnapshot) => 
            {
                const data         = querySnapshot.data()?.data
                const desiredOrder = [ 6046, 48242, 154914, 154830, 35232 ]

                const sorted = data?.sort((a: any, b: any) => 
                {
                    const indexA = desiredOrder.indexOf(parseInt(a.id))
                    const indexB = desiredOrder.indexOf(parseInt(b.id))

                    if (indexA === -1 && indexB === -1) return 0
                    if (indexA === -1) return 1
                    if (indexB === -1) return -1
                    return indexA - indexB
                })



                setNavigation(sorted)
            }
        )
    }, [ games, locations ])

    const handleSelectCountry = async (id: any, sport: any) => 
    {
        if (router?.asPath !== "/sports/") 
        {
            setAwaitLoadingHTTP(true)
        }

        setSelectedSport(sport.id ? sport.id : "esports")
        setVirtual(sport.id ? false : true)
        setSelectedLocation(id)
        // setSelectedLeague(null)
        setFilterPerLeague(true)
        setStartDate(null)

        if (router?.asPath !== "/sports/") 
        {
            redirectTo("/sports/", user, router, setOpenLoginModal)
        }
        else 
        {
            setReloadGames(true)
        }

        if (closePopularEvents) 
        {
            closePopularEvents(false)
        }

        setAwaitLoadingHTTP(false)
    }

    const handleSelectLeague = async (league: any, sport: any) => 
    {
        if (router?.asPath !== "/sports/") 
        {
            setAwaitLoadingHTTP(true)
        }

        setSelectedSport(sport.id ? sport.id : "esports")
        setVirtual(sport.id ? false : true)

        setSelectedLeague(league)

        setEndDate(null)
        setSelectedLocation(null)

        setReloadGames(true)

        if (router?.asPath !== "/sports/") 
        {
            redirectTo("/sports/", user, router, setOpenLoginModal)
        }

        if (closePopularEvents) 
        {
            closePopularEvents(false)
        }

        setAwaitLoadingHTTP(false)
    }


    // if (!isDesktop && openModalPopularEvents == true) 
    // {
    //     return (
    //         <div className={styles.navigationSportsMobile}>
    //             <div className={styles.container}>
    //                 <HeaderForMobileMenu closeFunction={closePopularEvents} />

    //                 <div className={styles.content}>
    //                     <AccordionSports
    //                         title={t("Esportes")}
    //                         image={<img src='/assets/img/sportscash.png' alt='' />}
    //                     >
    //                         {navigation?.length > 0 &&
    //             navigation?.map((sport: any, key: any) => (
    //                 <AccordionSport
    //                     key={key}
    //                     sport={sport}
    //                     quantity={sport?.count}
    //                     title={
    //                         selectedLanguage === "pt"
    //                             ? sport?.name_br
    //                             : sport?.name_ko
    //                     }
    //                     handler={closePopularEvents}
    //                 >
    //                     {sport?.children?.map((location: any, key2: any) => (
    //                         <AccordionSportItem
    //                             key={key2}
    //                             title={
    //                                 selectedLanguage === "pt"
    //                                     ? location?.name_br
    //                                     : location?.name_ko
    //                             }
    //                             image={<img src={getFlag(location)} alt='' />}
    //                             quantity={location?.count}
    //                             id={location.id}
    //                             sport={sport}
    //                             handler={handleSelectCountry}
    //                         >
    //                             {location?.children?.map((league: any, key3: any) => (
    //                                 <div
    //                                     key={key3}
    //                                     onClick={() => handleSelectLeague(league, sport)}
    //                                     className={styles.child}
    //                                 >
    //                                     <p className={styles.name}>
    //                                         {selectedLanguage === "pt"
    //                                             ? league?.name_br
    //                                             : league?.name_ko}
    //                                     </p>
    //                                     <p className={styles.name}>{league?.count}</p>
    //                                     <BsArrowRightShort />
    //                                 </div>
    //                             ))}
    //                         </AccordionSportItem>
    //                     ))}
    //                 </AccordionSport>
    //             ))}
    //                     </AccordionSports>
    //                 </div>
    //             </div>
    //         </div>
    //     )
    // }

    const handleLogoLink = async (path) => 
    {

        if ( router.asPath === "/") 
        {
            router.reload();
        }
        else 
        {
            await router.push(path);
        }
    }
    
    return (
        <>
            {THEME_SETTING.headerInMiddle &&
            <div className={styles.menuLeft}>
                <div className={styles.logoMenu}>
                    <a onClick={() => handleLogoLink("/")} className='logo'>
                        <img 
                            src={account?.logo?._url} 
                            alt='logo' 
                        />
                    </a>
                </div>

        
            </div>}
            <div className={styles.boxNavigation}>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/liveCasino", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Live-Casino.png" alt="" /> {t("live casino")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/slotsCasino", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Slot.png" alt="" /> 슬롯게임</p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=0", user, router, setOpenLoginModal)}
                        // onClick={() => router.push("/deposit/?tabIndex=0")}
                    >
                        <img src="/assets/icon/Deposit.png" alt="" /> {t("Depósito")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=1", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Withdraw.png" alt="" /> {t("Saque")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=2", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Money-Exchange.png" alt="" /> {t("Transferencias")}
                    </p>
                </div>
            </div>
            
            <div className={styles.boxNavigation}>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/event", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Event.png" alt="" />{t("Evento")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=7", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/News.png" alt="" />{t("Rules")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=3", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Message.png" alt="" />{t("Customer center")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=8", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Loosing-Bonus.png" alt="" />루징신청
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/deposit/?tabIndex=4", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/Chat.png" alt="" />쪽지함
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p
                        onClick={() => redirectTo("/perfil", user, router, setOpenLoginModal)}
                    >
                        <img src="/assets/icon/My-Page.png" alt="" />{t("My page")}
                    </p>
                </div>
                <div className={styles.itemContainer}>
                    <p onClick={()=>
                        window.location.reload(true)
                    }
                    >
                        <img src="/assets/icon/4208523.png" alt="" /><span>쿠키삭제</span>
                  
                    </p>
                </div>
            </div>
        </>
    )
}
