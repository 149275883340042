import { collectionDocument, countDocument } from "@/core-nextv3/document/document.api";
import { FLAT_FIXTURE_SETTING } from "@/setting/setting";
import { calls } from "@/core-nextv3/util/call.api";
import { format } from "date-fns-tz";

interface FilterProps {
    startDate?: any,
    endDate?: any,
    sportId?: any,
    leagueId?: any,
    virtual?: any,
    active?: any
    perPage?: any,
    page?: any,
    locationId?: any,
    live?: any,
    liveActive?: any
    selectedId?: any,
    orderBy?: any,
    highlight?: any,
    asc?: any
    statusId?: any
}

export const buildFilter = async ({ startDate, endDate, sportId, perPage, page, leagueId, virtual, active, locationId, live, liveActive, selectedId, orderBy, highlight, statusId, asc = true }: FilterProps) => 
{

    const query: any = {};
    const where      = [];

    if (active != undefined) 
    {
        where.push({
            field    : "active",
            operator : "==",
            value    : active
        })
    }

    if (startDate != undefined) 
    {
        where.push({
            field    : "startDate",
            operator : ">=",
            value    : format(startDate, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone : "Asia/Seoul" }),
            type     : { value : "Date" }
        })
    }

    if (endDate != undefined) 
    {
        where.push({
            field    : "startDate",
            operator : "<=",
            value    : format(endDate, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone : "Asia/Seoul" }),
            type     : { value : "Date" }
        })
    }

    if (virtual != undefined) 
    {
        where.push({
            field    : "virtual",
            operator : "==",
            value    : virtual
        })
    }

    if (sportId != undefined) 
    {
        where.push({
            field    : "sport.id",
            operator : "==",
            value    : sportId
        })
    }

    if (leagueId != undefined) 
    {
        where.push({
            field    : "league.id",
            operator : "==",
            value    : leagueId
        })
    }

    if (locationId != undefined) 
    {

        where.push({
            field    : "location.id",
            operator : "==",
            value    : "" + locationId
        })
    }

    if (perPage != undefined) 
    {
        query.perPage = perPage
    }

    if (page != undefined) 
    {
        query.page = page;
    }

    if (highlight != undefined) 
    {
        where.push({
            field    : "league.highlight",
            operator : "==",
            value    : highlight
        })
    }

    if (selectedId != undefined) 
    {
        where.push({
            field    : "id",
            operator : "==",
            value    : selectedId
        })
    }

    if (live != undefined) 
    {

        if (liveActive != undefined) 
        {
            where.push({
                field    : "inPlay.active",
                operator : "==",
                value    : liveActive
            })
        }
        else 
        {

            where.push({
                field    : "inPlay.enabled",
                operator : "==",
                value    : live
            })
        }
    }

    if (orderBy != undefined)
    {
        query.orderBy = orderBy
        query.asc     = asc;
    }

    if (statusId != undefined) 
    {
        where.push( {
            field    : "status.id",
            operator : "in",
            value    : statusId
        })
    }

   

    query.where = where;

    const [ betsResult, countResul ] = await calls(collectionDocument(FLAT_FIXTURE_SETTING.merge(query)), countDocument(FLAT_FIXTURE_SETTING.merge(query)));
    betsResult.count                 = countResul.count;

    return betsResult;
}

