export interface ISetting{
	merge  : Function,
	status : boolean
}

const setting = (data:any):ISetting => 
{
    const obj = Object.assign(
	{
		merge : function(data:any)
		{
			return setting(Object.assign({}, this, data));
		}
	}, data);

	Object.defineProperty(obj, "merge", { writable: true, enumerable: false, configurable: true })

	return obj;
};

export { setting }