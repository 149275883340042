import { AiOutlineClose } from "react-icons/ai";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { getAccountBalanceAllWithTimeout } from "@/core-nextv3/ximax2/ximax2.api";

export const ModalVideo = ({ gameUrl, setGameUrl }: any) => 
{
    const { user } = useContext(AuthContext);

    const handleClose = async () => 
    {
        setGameUrl(null);

        const setting:any = {
            currency : "KRW",
            document : {
                referencePath : user?.referencePath
            }
        }

        await getAccountBalanceAllWithTimeout(setting)
    }

    return (
        // <div className={styles.modalVideo} onClick={() => handleClose()}>
        <div className={styles.modalVideo}>
            <div className={styles.container} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalHeader}>
                    <AiOutlineClose
                        className={styles.closeModalSvg}
                        onClick={() => handleClose()}
                    />
                </div>
                <div className={styles.modalBody}>
                    <div className={styles.iframe}>
                        <iframe
                            width="80%"
                            height="80%"
                            src={gameUrl}
                        ></iframe>
                    </div>
                </div>

            </div>
        </div>
    );
}