import { List, ListItem, ListItemButton, ListItemIcon, ListItemText, Box, Link } from "@mui/material"
import { t } from "i18next"
import { CiViewList } from "react-icons/ci"
import { LuUserCog, LuUserPlus } from "react-icons/lu"
import { TfiHeadphoneAlt } from "react-icons/tfi"
import styles from "./styles.module.scss"
import { useTranslation } from "next-i18next"
import { TbLogout } from "react-icons/tb"
import redirectTo from "@/bet-nextv1/utils/redirectTo"
import user from "@/core-nextv3/model/user"
import router from "next/router"
import { AuthContext } from "@/bet-nextv1/context/AuthContext"
import { useSubCore } from "@/hooks/useSubCore"
import { useContext, useEffect, useRef } from "react"

export const  DrawerLeft: React.FC = ({ handleLogoutClick, toggleDrawer }) =>
{
    const { t }                 = useTranslation();
    const { user }              = useContext(AuthContext)
    const { setOpenLoginModal } = useSubCore()

    const handleclick = (route: string) => 
    {

        toggleDrawer(false)
        redirectTo(route, user, router, setOpenLoginModal)
    }


    return <List className={styles.drawerList}>
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=0'> */}
                <a onClick={() => handleclick("/liveCasino")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <img src="/assets/img/deposito.png" alt=""/> */}
                            <img src="/assets/icon/Live-Casino.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Depósito")} */}
                            {t("live casino")}
                        </ListItemText>
                   
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=1'> */}
                <a onClick={() =>handleclick("/slotsCasino")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <img src="/assets/img/payout.png" alt=""/> */}
                            <img src="/assets/icon/Slot.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Saque")} */}
                            슬롯게임
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/event'> */}
                <a onClick={() => handleclick("/deposit/?tabIndex=0")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <img src="/assets/img/event.png" alt=""/> */}
                            <img src="/assets/icon/Deposit.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Evento")} */}
                            {t("Depósito")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=7'> */}
                <a onClick={() => handleclick("/deposit/?tabIndex=1")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <img src="/assets/img/notice.png" alt=""/> */}
                            <img src="/assets/icon/Withdraw.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Avisos")} */}
                            {t("Saque")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>

        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=3'> */}
                <a onClick={() => handleclick("/deposit/?tabIndex=2")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <TfiHeadphoneAlt /> */}
                            <img src="/assets/icon/Money-Exchange.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Perguntas")} */}
                            {t("Transferencias")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=5'> */}
                <a onClick={() =>handleclick("/event")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <img src="/assets/img/betting-history.png" alt=""/> */}
                            <img src="/assets/icon/Event.png" alt="" />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {/* {t("Histórico de Apostas")} */}
                            {t("Evento")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        {/* <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/deposit/?tabIndex=7")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/Indications.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            {t("Rules")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem> */}
        <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/deposit/?tabIndex=7")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/News.png"     alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            {t("Rules")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                {/* <Link href='/deposit/?tabIndex=6'> */}
                <a onClick={() => handleclick("/deposit/?tabIndex=3")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            {/* <CiViewList /> */}
                            <img src="/assets/icon/Message.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            {/* {t("Histórico de Cash")} */}
                            {t("Customer center")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/deposit/?tabIndex=8")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/Loosing-Bonus.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            루징신청
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/deposit/?tabIndex=4")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/Chat.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            쪽지함
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        {/* <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => redirectTo("/deposit/?tabIndex=7", user, router, setOpenLoginModal)}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/News.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                           
                            지인추천
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem> */}
        <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => handleclick("/perfil")}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/My-Page.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            {t("My page")}
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        <ListItem className={styles.item}>
            <ListItemButton>
                <a onClick={() => window.location.reload(true)}>
                    <Box className={styles.box}>
                        <ListItemIcon className={styles.icon}>
                            <img src="/assets/icon/4208523.png" alt="" />
                        </ListItemIcon>
                        <ListItemText  className={styles.listText}>
                            쿠키삭제
                        </ListItemText>
                    </Box>
                </a>
            </ListItemButton>
        </ListItem>
        {user && <ListItem className={styles.item}>
            <ListItemButton>
                <div onClick={() => 
                {
                    toggleDrawer(false); handleLogoutClick()
                }}>
                    <Box className={styles.box} >
                        <ListItemIcon className={styles.icon}>
                            <TbLogout />
                        </ListItemIcon>
                        <ListItemText className={styles.listText}>
                            {t("Logout")}
                        </ListItemText>
                    </Box>
                </div>
            </ListItemButton>
        </ListItem>}
           
    </List>

}
