import { useCore } from "@/core-nextv3/core/core"
import styles from "./styles.module.scss"
import { AiFillStar } from "react-icons/ai"
import { HiEnvelope } from "react-icons/hi2"
import { useRouter } from "next/router"
import { useContext } from "react"
import { AuthContext } from "../context/AuthContext"
import { RiQuestionAnswerFill } from "react-icons/ri"
import { useTranslation } from "next-i18next"
import { AlertPopup } from "./AlertPopup"
import { MessagePopup } from "./MessagePopup"

export const NotificationPopup: React.FC<any> = ({
    countMessages,
    countMessagesOthers,
    notifications,
}:any) => 
{
    const { setAwaitLoadingHTTP } = useCore()
    const router                  = useRouter()

    const { user } = useContext(AuthContext)

    const handleClick = async (e: any) => 
    {
        e.stopPropagation()
        setAwaitLoadingHTTP(true)
        router.push("/deposit/?tabIndex=4")
        setAwaitLoadingHTTP(false)
    }

    // unificar hooks de mensagem caso apareça mais modulos
    return (
        <div className={styles.notificationPopup}>
            {countMessages > 0 && (
                <MessagePopup
                    notifications={notifications}
                    counter={countMessages}
                    tabIndex={"3"}
                />
            )}

            {countMessagesOthers > 0 && (
                <MessagePopup
                    notifications={notifications}
                    counter={countMessagesOthers}
                    tabIndex={"8"}
                />
            )}

            {notifications > 0 && (
                <AlertPopup 
                    counter={user?.views_advicesUsers} 
                    handler={handleClick}
                />
            )}
        </div>
    )
}
