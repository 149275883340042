import { Dialog } from '@mui/material'
import styles from './styles.module.scss'
import { useCore } from '@/core-nextv3/core/core'
import { useTranslation } from 'next-i18next'

export const Loading: React.FC<any> = () => {
  const { awaitLoadingHTTP, setAwaitLoadingHTTP, selectedLanguage } = useCore()
  const { t } = useTranslation()

  return (
    <>
      {awaitLoadingHTTP && (
        <Dialog
          className={styles.loading}
          open={awaitLoadingHTTP}
          onClose={setAwaitLoadingHTTP}
          sx={{ zIndex: 9999999 }}
          fullScreen
        >
          <div className={styles.splash}>
            <div className={styles.content}>
              <div className={styles.loader}></div>
              <p
                style={
                  selectedLanguage === 'pt'
                    ? { left: 'calc(50% - 51px)' }
                    : { left: 'calc(50% - 41px)' }
                }
              >
                {t('Aguarde')} ...
              </p>
            </div>
          </div>
        </Dialog>
      )}
    </>
  )
}
