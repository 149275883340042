import styles from './styles.module.scss'
import { useTranslation } from 'next-i18next'

export const MaintanceModal: React.FC<any> = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.maintanceModal}>
      <div className={styles.container}>
        <div className={styles.content}>
          <img src='/assets/img/Lock1.png' alt='test' />
          <p>{t('O site está em manutenção, por favor acesse-o mais tarde')}</p>
        </div>
      </div>
    </div>
  )
}
